import React from "react"
import Container from "../components/Container";
import Layout from "../components/Layout";
import * as styles from '../styles/home.module.css'
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';


const Page2 = () => {
  const { placeholderImage123 } = useStaticQuery(
          graphql`
      query {
        placeholderImage123: file(relativePath: {eq: "movember-hero.jpg"}) {
          childImageSharp {
            gatsbyImageData(
              width: 2000
              quality: 50
              webpOptions: {quality: 70}
              placeholder: BLURRED
            )
          }
        }
      }
    `
  )
  const pluginImage = getImage(placeholderImage123);


  return (
    <Layout>
        <BgImage image={pluginImage} className={styles.hero}>
          <div className={styles.overlay}>
            <div className={styles.bfOverlay}></div>
              <div className={styles.heroSection}>
                <h1 className={styles.heroTitle}>Movember</h1>
                <p>Powered by Solgar</p>
                <p>Veliki vodič kroz sve što trebate znati o muškom zdravlju</p>
                <div className={styles.videoWrapper}>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/lg1pL8_Y0Q8" title="Vox populi: Movember" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
          </div>
        </BgImage>

        <Container>
          <p className="marginTop"><strong>Studeni je, već tradicionalno, mjesec u kojemu se podiže svijest o zdravlju muškaraca. Nažalost, još uvijek vlada mišljenje da muškarci moraju biti jaki i da ‘’nikad nisu bolesni’’ zbog čega ne odlaze na vrijeme liječniku.</strong> Zato smo na jednom mjestu odlučili spojiti sve informacije o prevenciji najčešćih bolesti koje pogađaju muškarce, te smo istražili zašto se muškarci ‘’plaše’’ otići liječniku.</p>
          <p>Prim. dr. Dalibor Čičin-Šain iz <a className={styles.primaryText} href="https://urocentar.hr/" target="_blank" rel="noopener noreferrer">Poliklinike Uro-centar</a> objasnio nam je koje su najčešće vrste tumora od kojih obolijevaju muškarci i kako ih mogu spriječiti.</p>
          <p>‘’Postoji pet bolesti koje muškarce, onkološki, najčešće dođu glave i zbog čega se moraju kontrolirati’’ , objašnjava prim. dr. Čičin-Šain. </p>
          <p>“Prema učestalosti to su: <strong>karcinom pluća, karcinom prostate, karcinom debelog crijeva, zatim karcinom mokraćnog mjehura i karcinom testisa.</strong>“</p>
        
          <div class="wrapper marginMedium">
            <div>
              <StaticImage src="../images/cicin-sain.png" alt="cicin-sain" />
            </div>
            <div className="flexMiddle">
              <blockquote className={styles.quote}>
                <i>“Bolje otići liječniku ‘bez razloga’ nego kada postane prekasno.”</i>
              </blockquote>
            </div>
          </div>

          <h2>Rak pluća</h2>
          <p>Prvi korak u prevenciji raka pluća je, dakako, prestanak pušenja. Svako produženo kašljanje ili postojanje krvi u iskašljaju zahtijevaju detaljnu obradu. Može se raditi o tome da je pukla neka žilica pri iskašljavanju, ali može biti i znak raka. Liječnik opće prakse će u tom slučaju poslati pacijenta na daljnje pretrage kao što su citološka analiza iskašljaja, uzimanje tumorskih markera ili CT pluća.</p>

          <h2>Rak prostate</h2>
          <p>“Bolesnici često imaju pogrešnu predodžbu o tome da karcinom mora boljeti, krvariti ili uzrokovati bilo kakve smetnje. <strong>Karcinom prostate ne boli niti krvari, a do smetnji s mokrenjem može doći i ako pacijent ima povećanu, ali dobroćudnu prostatu’’</strong>, objašnjava prim. dr. Čičin-Šain.</p>
          <p>Dodaje da karcinom prostate treba tražiti, jer se on neće sam javiti. Štoviše, ne postoji nikakav znak koji bi pacijenta upućivao na to da se radi o <a className={styles.primaryText} href="https://www.centarzdravlja.hr/zdrav-zivot/musko-zdravlje/otkrijte-najcesce-mitove-o-raku-prostate-i-raku-testisa/" target="_blank" rel="noopener noreferrer">karcinomu prostate.</a></p>

          <div className="marginMedium">
            <blockquote className={styles.quote}>
              <p className={styles.textCenter}>“Karcinom prostate ne boli niti krvari.”</p>
            </blockquote>
          </div>

          <p>Posljednjih se 20-ak godina obavlja analiza krvi za pretragu PSA (prostata specifični antigen). PSA je specifična bjelančevina koju luči prostata, a njezina razina u krvi ovisi o tri faktora: </p>

          <p><strong>Veličini prostate</strong> – manja prostata luči manje PSA</p>
          <p><strong>Upali prostate</strong> – svaka upala prostate može dovesti do porasta PSA. Po sanaciji upale vrijednosti PSA obično padaju</p>
          <p><strong>Karcinomu prostate</strong> – povišeni PSA može biti pokazatelj i karcinoma</p>
        </Container>

        <div className={styles.backColor}>
          <Container> 
              <StaticImage src="../images/back-1.png" alt="infografika-1"/>
          </Container>
        </div>

        <Container> 
        <p className="marginTop">Prema tome, samo na temelju njega ne može se reći je li razlog povećanja upala, dobroćudno povećanje ili karcinom. Nivo PSA koji se smatra normalnim je dogovoran. Različite države imaju različito postavljenu granicu.<strong>U Hrvatskoj to iznosi do 4 nanograma (ng), dok je u nekim zemljama 3.5 ng ili čak 2.8 ng.</strong>  Granica varira i temelji se na matematičko-statističkoj vjerojatnosti da bolesnici koji imaju PSA ispod tog broja najvjerojatnije nemaju karcinom prostate. Međutim, 10% bolesnika s karcinomom prostate ima uredan PSA. npr. ako netko ima PSA 1 ng, iduće godine 0,9 ng, a dogodine mu naraste na 3 ng i dalje se nalazi unutar granice normale, ali je u konkretnom slučaju došlo do trostrukog porasta što znači da potencijalno može imati karcinom. Također, vrijednosti PSA iznad 4 ng ne moraju nužno ukazivati na karcinom, već se može raditi o upali ili dobroćudnom povećanju prostate.</p>
        <p>Sljedeći korak je detaljna urološka obrada, razgovor s pacijentom, ultrazvuk prostate i dodatne analize kao što je magnetska rezonanca. U onim slučajevima gdje postoji opravdana sumnja radi se biopsija prostate kod koje se dijagnoza mora potvrditi.</p>
        
        <a className={styles.primaryText} href="http://www.solgar.hr/index.php/Proizvodi/12/488/Cink%20kelat" target="_blank" rel="noopener noreferrer">
        <StaticImage src="../images/solgar-banner.png" alt="solgarbanner-1"/>
        </a>

        <p className="marginTop">Jednom kada se postavi dijagnoza vrijedi pravilo, kao i kod svih tumora, što se prije nađe bolja je vjerojatnost izlječenja. Ako je karcinom lokaliziran samo na prostatu i nije se nigdje proširio obično se izvodi radikalna operacija odstranjenja prostate ili radikalno zračenje. Potonje je rezervirano za ljude starije životne dobi u kojoj bi operacija bila rizična.</p>
        <p>U Hrvatskoj odnedavno postoji uređaj za robotsku radikalnu operaciju i naši liječnici skupljaju prva iskustva. U ustanovama se i dalje rade klasične otvorene operacije ili laparoskopske operacije. To su sve načini radikalnog odstranjenja prostate. Ako je bolest otkrivena na vrijeme i nije se nigdje proširila, problem je često riješen.</p>
        <p>S druge pak strane, upozorava prim. dr. Čičin-Šain, ako se oklijeva s <a className={styles.primaryText} href="https://www.centarzdravlja.hr/zdrav-zivot/musko-zdravlje/ove-lijecnicke-preglede-mora-obaviti-svaki-muskarac/" target="_blank" rel="noopener noreferrer">odlaskom k liječniku</a>, postoji mogućnost da se bolest proširi, metastazira i tada operacija više ne dolazi u obzir. Kod takvih bolesnika provodi se hormonska terapija ili kemoterapija.</p>
        <p>Karcinom prostate najčešće se pojavljuje u starijoj životnoj dobi, nakon 45-te godine života. Iako postoje i <strong>slučajevi ranijeg obolijevanja, oni su rijetki</strong>. Od osamdesete godine života nadalje, žarište karcinoma prostate može se pronaći kod 40% muškaraca, no to žarište nije toliko dramatično kao kod muškaraca u 50-im godinama, objašnjava prim. dr. Čičin-Šain. Dodaje da postoji matematička projekcija prema kojoj, kada bi muškarci živjeli 120 godina, svi bi umrli od karcinoma prostate.</p>
        </Container>

        <div>
          <StaticImage className={styles.imageBreak} src="../images/movember1.jpg" alt="movember"/>
        </div>

        <Container> 
        <h2 className="marginTop">Rak debelog crijeva</h2>
        <p>U Hrvatskoj se posljednjih godina provodi javno zdravstvena akcija detekcije krvi u stolici, kao jedan od prvih znakova postojanja karcinoma debelog crijeva. Osobe koje su zainteresirane trebale bi se prijaviti, a na njihovu adresu će u kratkom roku stići omotnica s testovima. Testiranje je potpuno besplatno i trebalo bi se odazvati u što većem broju, kako bi se eventualni karcinom uhvatio u ranoj fazi. Postoje i krvne pretrage, određivanje tumorskih markera kao npr. CEA i Ca 19-9. No, treba znati da oni nisu povišeni kod svih tumora.</p>
        <p><strong>Nakon navršene 50-te godine života preporučuje se svakih pet godina ići na kolonoskopiju.</strong> Naravno, pokaže li nalaz bilo kakve manje tumore i nepravilnosti potrebno je ići češće. </p>
        <p>Kolonoskopija nije samo dijagnostička metoda, već i terapijska, s obzirom na to da se sve pronađene promjene mogu odmah tretirati te se može uzeti uzorak za histološku analizu. Neke promjene se mogu odmah tretirati laserom, tako da možemo napraviti razliku između onih koje ne zahtijevaju nikakvu daljnju akciju od onih za koje je potrebno napraviti operativni zahvat ili ih dalje pratiti. Kod ovih metoda kao detekcija se koristi nalaz prisutnosti krvi u stolici.</p>
        </Container>

        <div className={styles.backColor}>
          <Container> 
              <StaticImage src="../images/back-2.png" alt="infografika-2"/>
          </Container>
        </div>

        <Container> 
        <h2 className="marginTop">Rak mjehura</h2>
        <p>Jedan od prvih signala je slučajni nalaz krvi u mokraći pri rutinskom laboratorijskom testu. Mokraća je naizgled uredne boje, ali se mikroskopom nađe povećan broj crvenih krvnih zrnca (eritrocita). To nazivamo <strong>mikrohematurijom.</strong></p>
        
        <div className="marginMedium">
            <blockquote className={styles.quote}>
              <p className={styles.textCenter}>“Karcinomi mjehura su 30 puta češći kod pušača. <br />Potreban još jedan razlog da prestanete pušiti?“  </p>
            </blockquote>
        </div>
        
      <p>Dijagnostika počinje ultrazvučnim pregledom urotrakta. Važno je pri pregledu imati pun mjehur kako bi se mogla dobro pogledati njegova stijenka. Ako se nađe neravnina ili polipoidna izraslina, potrebna je dodatna obrada. To je u pravilu citološka analiza tri ili više uzoraka urina, u kojima se traže maligne stanice. Na kraju slijedi cistoskopija. Posebni optički instrument uvodi se kroz mokraćnu cijev u mjehur i detaljno pregledava njegova stijenka. Uzrok mikrohematurije mogu biti kamenci u bubregu, ali i druga patološka stanja bubrega, kao što su tumori i autoimuna oštećenja (glomerulonefritis).</p>
      <p>Za definitivnu dijagnozu potrebno je učiniti dodatne biokemijske analize i radiološki snimak bubrega i mokraćnih kanala, tzv. MSCT urografiju.</p>
      </Container>

      <div>
          <StaticImage className={styles.imageBreak} src="../images/movember2.jpg" alt="movember"/>
      </div>

      <Container> 
      <h2 className="marginTop">Rak testisa</h2>
      <p>Tumor testisa jedan je od rijetkih koji je moguće izliječiti u 100% slučajeva ako se otkrije u ranoj fazi. Svi ostali tumori zahtijevaju daljnje postupke i terapiju. </p> 
      <p><strong>Da bi se rak testisa otkrio na vrijeme prvi je korak samopregled prilikom tuširanja.</strong> Drugi korak, jednako važan, je u slučaju pronalaska kvržice pronaći dovoljno hrabrosti i otići doktoru. </p>
      <p>Najveći je problem što nam pacijenti dolaze s kvrgom u testisima koju imaju već <strong>6 mjeseci ili čak godinu dana</strong>, ali im je bilo neugodno ići na pregled i nadali su se da će proći samo od sebe. Često se dogodi da pacijenti dođu na pregled prostate pa im tada otkrijemo i <a className={styles.primaryText} href="https://www.centarzdravlja.hr/zdrav-zivot/musko-zdravlje/bol-u-testisima/" target="_blank" rel="noopener noreferrer">kvržicu na testisima.</a></p>

      <div className="marginMedium">
            <blockquote className={styles.quote}>
              <p className={styles.textCenter}>“Dakle, ako se nađe bilo kakva kvržica potrebno je odmah otići liječniku.“ </p>
            </blockquote>
      </div>

      <p>Liječnik opće prakse će u tom slučaju, objašnjava prim. dr. Čičin-Šain, obaviti pregled i poslati pacijenta urologu. Dodaje da je bolje potražiti mišljenje doktora i kada pacijent nije siguran da je nešto napipao, nego ne doći na pregled i ne prepoznati problem na vrijeme.</p>
      <p><strong>Tumori testisa su u 99% slučajeva zloćudni, međutim, ako su pronađeni u prvoj fazi dovoljno je samo operativno odstranjenje tog testisa.</strong> Kod nekih se slučajeva može dogoditi da je potrebno proći jedan do dva ciklusa kemoterapije, ali ona se u tom slučaju vrlo dobro podnosi te je problem riješen. </p>
      <p>Pregled testisa roditelji bi djeci trebali raditi od najranije dobi, jer, ističe prim. dr. Čičin-Šain, postoje tri dobne skupine kod kojih se rak testisa najčešće pojavljuje, iako se može javiti u svakoj životnoj dobi. Prva je dječja dob, druga je između 20 i 40 godina života, a treća je oko 60 i 70 godina.</p>

      <a className={styles.primaryText} href="http://www.solgar.hr/index.php/Proizvodi/12/488/Cink%20kelat" target="_blank" rel="noopener noreferrer">
        <StaticImage src="../images/solgar-banner.png" alt="solgarbanner-1"/>
      </a>

      <h2 className="marginTop">Problem s potencijom</h2>
      <p>Postoji još jedan problem, koji nije zloćudna bolest, ali opterećuje pacijenta a i njegovog partnera.</p>
      <p><a className={styles.primaryText} href="https://www.centarzdravlja.hr/zdrav-zivot/musko-zdravlje/erektilna-disfunkcija-uzroci-i-lijecenje/" target="_blank" rel="noopener noreferrer">Erektilna disfunkcija</a> nije samo izostanak erekcije već može biti i pokazatelj brojnih bolesti koje do nje dovode. Ona se najčešće može uočiti kod dijabetičara. Problem s potencijom je taj koji pacijenta natjera da ode urologu na pregled tijekom kojeg se često otkriju i druge bolesti kao što su neurološke, vaskularne i hormonske.</p>
      
      <h2 className="marginTop">Zašto se toliko bojimo odlaska liječniku?</h2>
      <p>Jedna od glavnih stavki za uspješno izlječenje, posebice raka, jest otkrivanje bolesti u ranom stadiju. Što se bilo koja bolest što ranije otkrije, veće su šanse za potpuno i uspješno izlječenje. Nažalost, često prođu mjeseci i mjeseci prije nego se odlučimo po prvi posjetiti liječnika. Posebno muškarci dugo niječu vidljive i osjetne simptome, uvjeravajući se da “to nije ništa”, no često dijagnoza pokaže uznapredovali stadij bolesti. Zbog čega se muškarci više “boje” liječnika, objasnio nam je <strong>mag.psych. Luka Škrinjarić</strong>, vlasnik <a className={styles.primaryText} href="http://mentalnitrening.com/" target="_blank" rel="noopener noreferrer">tvrtke Mentalni trening.</a></p>
      
      <div class="wrapper marginMedium">
        <div>
            <StaticImage src="../images/luka-skrinjaric.png" alt="luka-skrinjaric" />
          </div>
          <div className="flexMiddle">
            <blockquote className={styles.quote}>
              <i>“Muškarci znaju patiti od sindroma superheroja, žele vidjeti sebe kao vječno jake osobe”</i>
            </blockquote>
        </div>
      </div>

      <p>“Pojednostavljeno, možemo reći da postoji nekoliko uzroka ovakvom ponašanju muškaraca. Jedan od razloga jest strah od dijagnoze ili strah od potencijalnog lošeg ishoda. Drugi uzrok je ono što bi mogli nazvati sindrom superheroja – muškarci žele vidjeti sebe kao vječno jake osobe, koji se mogu nositi s bilo čime. Samim time, dolazimo do trećeg uzroka, a to je ranjivost. Muškarci ne žele narušavati sliku o sebi jer posjete liječniku doživljavaju kao slabost”, istaknuo je mag.psych. Škrinjarić.</p>
      <p>U nastavku objašnjava da muškarci u mnogo manjem broju traže pomoć liječnika nego žene. Isto tako muškarci u većem postotku pate od kroničnih bolesti, imaju veću stopu smrtnosti za vodeće bolesti te žive pet do sedam godina kraće od žena.</p>
      <p>“Neka istraživanja na zapadu kažu da čak dvije trećine muškaraca izbjegava posjet liječniku koliko god je moguće, a čak 37 % muškaraca suzdržava se od iznošenja svih informacija čak i kada dođu liječniku. Dakako, ovo su sve zabrinjavajuće statistike koje pokazuju da postoji velika razlika među spolovima u odnosu na liječenje”, pojašnjava mag.psych. Škrinjarić.</p>
      <p>Isto tako je, kako ističe mag.psych. Škrinjarić, zanimljiva činjenica da su istraživanja pokazala da oni muškarci koji iskazuju više vrijednosti na skali maskulinosti rjeđe posjećuju liječnika i suzdržavaju se od prenošenja potpunih informacija mnogo više od onih koji nisu toliko izraženi na istoj skali.</p>

      <div className="marginMedium">
            <blockquote className={styles.quote}>
              <p className={styles.textCenter}>Patite li i vi od visoko izražene maskulinosti?</p>
            </blockquote>
      </div>

      <p><strong>“Visoko izražena maskulinost bi bila duboka vjerovanja o sebi, koja su definitivno kulturalno, odgojem usvojena, da muškarci trebaju biti hrabri, jaki, ovisni sami o sebi, da ne smiju pokazivati slabosti jer to nije nešto što pravi muškarci rade.</strong> Fraze koje su usvojene odgojem, kao što su: ‘Budi muškarac, ne pokazuj slabosti. Ne pokazuj emocije. Pravi muškarac ne plače. Ili boli te kao curicu’, jest nešto što potencira ove kulturalne norme. Istraživanja pokazuju da što su muškarci imali izraženije ovakve norme, to su više preferirali odlazak k muškom liječniku. No, kada su proveli istraživanje kome će muškarci s visoko izraženom maskulinošću prenijeti više simptoma, pokazalo se da će biti otvoreniji prema liječnicama u odnosu na liječnike. Moglo bi se zaključiti da preferiraju muške liječnike, ali njima i zataje veći broj simptoma. Jedan od razloga tome jest što time ne pokazuju toliko slabosti drugom muškarcu”, objašnjava mag.psych. Škrinjarić.</p>
      
      <div className="marginMedium">
            <blockquote className={styles.quote}>
              <p className={styles.textCenter}>“Niti jedan muškarac nije Superman”</p>
            </blockquote>
      </div>

      <p>Veoma važno je prihvatiti da niti jedan muškarac nije “Superman”. Potrebno je prihvatiti da je vlastito zdravlje jednako važno kao i zdravlje partnerice i zdravlje djece. Mag.psych. Škrinjarić naglašava kako muškarci nastoje biti zaštitnici svoje obitelji, stoga nastoje biti snažni i rješavati bilo koji problem koji se putem nađe. No, to ne bi smjelo stajati u kontrast s time da u određenim situacijama trebaju zdravstvenu pomoć.</p>
      </Container>

      <div className={styles.backColor}>
          <Container> 
              <StaticImage src="../images/back-3.png" alt="infografika-1"/>
          </Container>
      </div>

      <Container>
        <p className="marginTop"><strong>“Smatram da je prvi korak, priznati da je najbolje rješenje potražiti pomoć, da to muškarce ne čini ‘manje muškima’ već da je to korak koji moraju napraviti ne bi li i dalje mogli obnašati svoju ulogu važnog potpornja obitelji.</strong> Ono što se pokazalo da muškarcima pomaže jest da se o uobičajenim zdravstvenim problemima muškaraca govori, kako u zatvorenim ‘muškim zajednicama’, tako i javno. Ako se taj zdravstveni problem ne gura pod tepih, ako se od njega ne radi tabu, ili dojam da to pogađa samo one ‘slabe’ muškarce, tada će se veći broj muškaraca odlučiti na odlazak liječniku. U ovom također jako pomažu javne zdravstvene kampanje koje normaliziraju zdravstvene probleme muškaraca i potiču promjenu društvenih normi u tom pogledu.”</p>
        <p>Mag.psych. Škrinjarić ističe kako partnerica može imati veliku ulogu u podršci muškarcu da se odluči na promjene. U Sjedinjenim Američkim Državama, gdje je zdravstvo većinom privatizirano, čak se nude i popusti na usluge ako žene na pregled dovedu i svog partnera. </p>
        <p><strong>“Vjerujem da će svaki liječnik kazati da je mentalno stanje u procesu liječenja iznimno važan prediktor bržeg i uspješnijeg oporavka.</strong> Nebrojena istraživanja pokazuju da je socijalnapotpora nemjerljiv faktor koji pridonosi ozdravljenju, a upravo socijalna potpora kreće od onog najužeg kruga obitelji pa sve do šire zajednice”, ističe za kraj mag.psych. Škrinjarić.</p>
        <p className="marginTop">
        Autor: CentarZdravlja<br />
        Foto: 123RF, Freepik
        </p>

        <p>Više o zdravlju redovito pratite na portalu <a className={styles.primaryText} href="https://www.centarzdravlja.hr/" target="_blank" rel="noopener noreferrer">Centar Zdravlja.</a></p>
      
      </Container>


    </Layout>
  );
}

export default Page2